import { APP_INITIALIZER, NgModule } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { AppConfService } from 'src/app/core/conf/app-conf.service';

function initializeKeycloak(keycloakService: KeycloakService, appConfService: AppConfService) {
  return () =>
    appConfService.load().then(() => {
      return keycloakService.init({
        config: {
          url: appConfService.env!.authority,
          realm: 'ayming',
          clientId: appConfService.env!.clientId,
        },
        initOptions: {
          onLoad: 'login-required',
          redirectUri: window.location.href,
          flow: 'implicit',
        },
      });
    });
}

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AppConfService],
    },
  ],
})
export class AuthModule {}
