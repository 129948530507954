<mat-toolbar color="primary">
  <!-- logo -->
  <img src="assets/images/logo/logo.svg" class="me-5" />

  <!-- breadcrumb -->
  <app-breadcrumb></app-breadcrumb>

  <div class="flex-fill"></div>

  <!-- full name -->
  <button mat-button [matMenuTriggerFor]="nameMenu">
    <span class="mat-subtitle-2 mr-3">{{ getUserFullName() }}</span>
    <mat-menu #nameMenu="matMenu">
      <button mat-menu-item class="d-flex justify-content-center" (click)="profile()">
        {{ 'appNavBar.nav_bar_go_to_profile' | transloco }}
      </button>
      <button mat-menu-item class="d-flex justify-content-center" (click)="testRandomCall()">
        {{ 'appNavBar.nav_bar_go_to_change_password' | transloco }}
      </button>
      <button mat-menu-item class="d-flex text-warn" (click)="logout()">
        <div class="logout justify-content-center">
          <mat-icon color="warn">logout</mat-icon>
          <span>{{ 'appNavBar.nav_bar_disconnect_button' | transloco }}</span>
        </div>
      </button>
    </mat-menu>
  </button>

  <!-- use the following code snippet to create a button for CGU -->
  <button mat-button>
    <span class="mat-subtitle-2 mr-3 text-accent">CGU</span>
  </button>

  <!-- uncomment the following code snippet to create a button for CGU -->
  <!-- <button mat-button [matMenuTriggerFor]="cguMenu">
    <span class="mat-subtitle-2 mr-3 text-accent">CGU</span>
    <mat-menu #cguMenu="matMenu">
      <button mat-menu-item [routerLink]="''">
        {{ 'nav_bar_cgu_button' }}
      </button>
    </mat-menu>
  </button> -->

  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon class="text-accent">settings</mat-icon>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="toAdminSpace()">
        {{ 'appNavBar.admin_button' | transloco }}
      </button>
    </mat-menu>
  </button>

  <!-- Go To hotline -->
  <button mat-icon-button>
    <mat-icon class="text-accent">help_outline</mat-icon>
  </button>
</mat-toolbar>
