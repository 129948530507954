import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppContainer } from './app.container';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakAngularModule } from 'keycloak-angular';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './core/transloco/transloco-root.module';
import { AppDataAdapter, AppDateFormats } from './core/transloco/app-data-adapter';
import { Platform } from '@angular/cdk/platform';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AppState } from './core/store/app/app.state';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { MedicalCenterState } from 'src/app/core/store/medicalcenter/medicalcenter.state';
import { CollaboratorState } from 'src/app/core/store/collaborator/collaborator.state';
import { EstablishmentState } from 'src/app/core/store/establishment/establishment.state';
import { MedicalCheckupState } from 'src/app/core/store/medical-checkup/medical-checkup.state';
import { CanDeactivateGuard } from './core/services/canComponentDeactivate';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [AppContainer],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    KeycloakAngularModule,
    HttpClientModule,
    TranslocoRootModule,
    NgxsModule.forRoot([AppState, CollaboratorState, EstablishmentState, MedicalCenterState, MedicalCheckupState]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgbModule,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDataAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
    { provide: CanDeactivateGuard },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: AppDateFormats,
    },
    DatePipe,
  ],
  bootstrap: [AppContainer],
  exports: [],
})
export class AppModule {}
