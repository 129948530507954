<ul class="breadcrumb">
  <li *ngFor="let breadcrumb of breadcrumbs; let last = last">
    <a *ngIf="!last; else isLast" (click)="clickedElement(breadcrumb)">
      {{ breadcrumb.label | transloco }}
    </a>
    <ng-template #isLast>
      <span class="active">
        {{ breadcrumb.label | transloco }}
      </span>
    </ng-template>
  </li>
</ul>
