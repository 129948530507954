import { Action, Selector, StateContext } from '@ngxs/store';
import { ListResultModel } from 'src/app/core/models/list-result.model';
import { MedicalCheckupListItemModel } from 'src/app/core/models/medical-checkup-list-item.model';
import { SetCurrentMedicalCheckup } from 'src/app/core/store/medical-checkup/medical-checkup.actions';
import { MedicalCheckupModel } from 'src/app/core/models/medical-checkup.model';
import { Injectable } from '@angular/core';

export interface MedicalCheckupStateModel {
  medicalCheckupList: ListResultModel<MedicalCheckupListItemModel> | null;
  currentMedicalCheckup: MedicalCheckupModel | null;
}

@Injectable()
export class MedicalCheckupState {
  @Selector()
  static getCurrentMedicalCheckup(state: MedicalCheckupStateModel): MedicalCheckupModel | null {
    return state.currentMedicalCheckup;
  }

  @Action(SetCurrentMedicalCheckup)
  setCurrentMedicalCheckup(context: StateContext<MedicalCheckupStateModel>, { payload }: SetCurrentMedicalCheckup) {
    const state = context.getState();
    context.setState({
      ...state,
      currentMedicalCheckup: payload,
    });
  }
}
