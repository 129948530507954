import { Injectable } from '@angular/core';
import { BaseApi, MEDICAL_CHECKUP_URL } from 'src/app/core/api/base.api';
import { HttpClient } from '@angular/common/http';
import { MedicalCheckupListItemModel } from 'src/app/core/models/medical-checkup-list-item.model';
import { AppConfService } from 'src/app/core/conf/app-conf.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { QueryParametersModel } from 'src/app/core/models/query-parameters.model';
import { ListResultModel } from 'src/app/core/models/list-result.model';
import { MedicalCheckupModel } from 'src/app/core/models/medical-checkup.model';
import { ContactCollaboratorModel } from '../models/contact-collaborator.model';
import { ContactMedicalCenterModel } from '../models/contact-medical-center.model';
import { ProfessionalEnum } from 'src/app/core/enum/professional-enum';
import { PlanedCheckupModel } from 'src/app/core/models/planed-checkup.model';
import { OccasionalCheckupModel } from 'src/app/core/models/occasionalCheckup.model';
import { ReplanCheckupModel } from 'src/app/core/models/replan-checkup.model';
import { DunningReportRequestModel } from 'src/app/core/models/dunning-report-request.model';
import { MedicalCheckupEncloseModel } from 'src/app/core/models/medical-checkup-enclose.model';
import { AbandonMedicalCheckupModel } from 'src/app/core/models/abandon-medical-checkup.model';
import { ContactDunningCenterModel } from 'src/app/core/models/contact-dunning-center.model';
import { OverviewDataQueryParamsModel } from 'src/app/core/models/overviewDataQueryParams.model';
import { OverviewDataModel } from 'src/app/core/models/overviewData.model';
import { BulkSetVacationCheckupModel } from 'src/app/core/models/bulk-set-vacation-checkup.model';
import { UpdateCommentModel } from '../models/updateComment.model';

@Injectable({
  providedIn: 'root',
})
export class MedicalCheckupApi extends BaseApi<MedicalCheckupModel> {
  END_POINT_URL = MEDICAL_CHECKUP_URL;

  constructor(http: HttpClient, config: AppConfService, route: ActivatedRoute) {
    super(http, config, route);
  }

  list(queryParams?: QueryParametersModel): Observable<ListResultModel<MedicalCheckupListItemModel>> {
    return this.httpClient.get<ListResultModel<MedicalCheckupListItemModel>>(`${this.api}`, {
      params: { ...queryParams },
    });
  }

  overviewData(queryParams?: OverviewDataQueryParamsModel): Observable<OverviewDataModel> {
    return this.httpClient.get<OverviewDataModel>(`${this.api}/OverviewData`, {
      params: { ...queryParams },
    });
  }

  uploadFile(id: number, file: FormData): any {
    return this.httpClient.post<any>(`${this.api}/${id}/Documents`, file);
  }

  getAllowedProfessionals(id: number): Observable<ProfessionalEnum[]> {
    return this.httpClient.get<ProfessionalEnum[]>(`${this.api}/${id}/allowedprofessionals`, {});
  }

  contactCollaborator(id: number, model?: ContactCollaboratorModel): Observable<MedicalCheckupModel> {
    return this.httpClient.post<MedicalCheckupModel>(`${this.api}/${id}/ContactCollaborator`, model);
  }

  contactMedicalCenter(id: number, model?: ContactMedicalCenterModel): Observable<any> {
    return this.httpClient.post<any>(`${this.api}/${id}/ContactMedicalCenter`, model);
  }

  planCheckup(id: number, value: PlanedCheckupModel): Observable<MedicalCheckupModel> {
    return this.httpClient.post<any>(`${this.api}/${id}/PlanCheckup`, value);
  }

  manuallyPlanCheckup(payload: OccasionalCheckupModel): Observable<MedicalCheckupModel> {
    return this.httpClient.post<MedicalCheckupModel>(`${this.api}/ManuallyPlanCheckup`, payload);
  }

  sendReplanCheckup(id: number, value: ReplanCheckupModel): Observable<MedicalCheckupModel> {
    return this.httpClient.post<any>(`${this.api}/${id}/ReplanCheckup`, value);
  }

  dunningReportRequest(id: number, value: DunningReportRequestModel): Observable<MedicalCheckupModel> {
    return this.httpClient.post<any>(`${this.api}/${id}/DunningReportRequest`, value);
  }

  sendConvocation(id: number, value: FormData): Observable<MedicalCheckupModel> {
    return this.httpClient.post<any>(`${this.api}/${id}/SendConvocation`, value);
  }

  contactDunningCenter(id: number, value?: ContactDunningCenterModel): Observable<MedicalCheckupModel> {
    return this.httpClient.post<any>(`${this.api}/${id}/ContactDunningCenter`, value);
  }

  encloseCheckup(id: number, value: FormData) {
    return this.httpClient.post<MedicalCheckupEncloseModel>(`${this.api}/${id}/EncloseCheckup`, value);
  }

  abandonMedicalCheckup(id: number, value: AbandonMedicalCheckupModel): Observable<MedicalCheckupModel> {
    return this.httpClient.post<any>(`${this.api}/${id}/AbandonMedicalCheckup`, value);
  }

  bulkSetVacationCheckup(payload: BulkSetVacationCheckupModel) {
    return this.httpClient.post<any>(`${this.api}/BulkSetVacations`, payload);
  }

  getDocuments(id: number) {
    return this.httpClient.get(`${super.api}/${id}/documents`);
  }

  openDocument(id: number, fileName: string) {
    return this.httpClient.get(`${super.api}/${id}/download/${fileName}`, { responseType: 'blob' });
  }

  updateComment(id: number, commentData: UpdateCommentModel) {
    return this.httpClient.post<UpdateCommentModel>(`${this.api}/${id}/UpdateComment`, commentData);
  }
}
