import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { Breadcrumb } from 'src/app/core/interfaces/Breadcrumb';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: Breadcrumb[];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.breadcrumbs = [];
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged(),
        tap(() => {
          this.breadcrumbs = this.initBreadcrumbs(this.activatedRoute.root);
        }),
      )
      .subscribe();
  }

  initBreadcrumbs(route: ActivatedRoute): Breadcrumb[] {
    if (route.firstChild) {
      return this.initBreadcrumbs(route.firstChild);
    }
    const url = this.router.url;

    // Root route
    const breadcrumbs: Breadcrumb[] = [{ label: 'my-perimeter', url: '' }];

    // Main routes (app-routing)
    if (url.includes('management')) {
      breadcrumbs.push({
        label: 'management-short',
        url: this.replaceParamInPath('tenant/:tenantId/management', route.snapshot),
      });
    }

    if (url.includes('complementary-acts')) {
      breadcrumbs.push({
        label: 'complementary-acts',
        url: this.replaceParamInPath('tenant/:tenantId/complementary-acts', route.snapshot),
      });
    }

    if (url.includes('establishments/')) {
      breadcrumbs.push({
        label: 'establishment-space',
        url: this.replaceParamInPath('establishment-info', route.snapshot),
      });
    }

    if (url.includes('medical-checkup-space')) {
      breadcrumbs.push({
        label: 'medical-checkup-space',
        url: this.replaceParamInPath('checkup-management', route.snapshot),
      });
    }

    if (url.includes('collaborator-space')) {
      breadcrumbs.push({
        label: 'collaborator-space',
        url: this.replaceParamInPath('tenant/:tenantId/management/collaborator-space/:collaboratorId', route.snapshot),
      });
    }

    if (url.includes('medical-centers/')) {
      breadcrumbs.push({
        label: 'medical-center-space',
        url: this.replaceParamInPath('medical-center-info', route.snapshot),
      });
    }

    return breadcrumbs;
  }

  replaceParamInPath(path: string, routeSnapshot: ActivatedRouteSnapshot): string {
    let newPath = '';
    const urlSegments = path?.split('/');
    urlSegments.forEach((segment) => {
      const isDynamicRoute = segment?.startsWith(':');
      if (isDynamicRoute && !!routeSnapshot) {
        const paramName: string = segment?.split(':')[1] ?? '';
        if (paramName && routeSnapshot.paramMap.has(paramName)) {
          segment = routeSnapshot.paramMap.get(paramName)!;
        }
      }
      newPath += `/${segment}`;
    });
    return newPath;
  }

  async clickedElement(breadcrumb: Breadcrumb) {
    await this.router.navigate([breadcrumb.url]);
  }
}
