import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { AuthModule } from 'src/app/core/auth/auth.module';
import { AppConfService } from 'src/app/core/conf/app-conf.service';
import { NavbarLayoutComponent } from './layouts/navbar-layout/navbar-layout.component';
import { BreadcrumbComponent } from './layouts/breadcrumb/breadcrumb.component';
import { TranslocoModule } from '@ngneat/transloco';
import { PermissionsService } from './services/permissions.service';

export function appInit(appConfigService: AppConfService) {
  return () => appConfigService.load();
}

@NgModule({
  declarations: [NavbarLayoutComponent, BreadcrumbComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    RouterModule,
    AuthModule,
    TranslocoModule,
  ],
  exports: [NavbarLayoutComponent, BreadcrumbComponent],
  providers: [
    {
      provide: PermissionsService,
    },
  ],
})
export class CoreModule {}
