import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetAppClient, SetUserMetadata } from './app.actions';
import { TenantModel } from 'src/app/core/models/tenant.model';
import { UserMetadataModel } from 'src/app/core/models/user-metadata.model';
import { Injectable } from '@angular/core';

export interface AppStateModel {
  tenant: TenantModel | null;
  userMetadata: UserMetadataModel | null;
}

@State<AppStateModel>({
  name: 'AppState',
  defaults: {
    tenant: null,
    userMetadata: null,
  },
})

@Injectable()
export class AppState {
  // Selectors
  @Selector() static getCurrentAppClient(state: AppStateModel): TenantModel | null {
    return state.tenant;
  }

  @Selector() static getUserMetadata(state: AppStateModel): UserMetadataModel | null {
    return state.userMetadata;
  }

  // Actions
  @Action(SetAppClient)
  setAppClient(context: StateContext<AppStateModel>, { payload }: SetAppClient) {
    const state = context.getState();
    context.setState({
      ...state,
      tenant: payload,
    });
  }

  @Action(SetUserMetadata)
  SetUserMetadata(context: StateContext<AppStateModel>, { payload }: SetUserMetadata) {
    const state = context.getState();
    context.setState({
      ...state,
      userMetadata: payload,
    });
  }
}
