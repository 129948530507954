import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EstablishmentModel } from 'src/app/core/models/establishment.model';
import { SetCurrentEstablishment } from 'src/app/core/store/establishment/establishment.actions';

export interface EstablishmentStateModel {
  establishment: EstablishmentModel | null;
}

@State<EstablishmentStateModel>({
  name: 'EstablishmentState',
  defaults: {
    establishment: null,
  },
})

@Injectable()
export class EstablishmentState {
  // Selectors
  @Selector()
  static getCurrentEstablishment(state: EstablishmentStateModel): EstablishmentModel | null {
    return state.establishment;
  }

  // Actions
  @Action(SetCurrentEstablishment)
  SetCurrentEstablishment(context: StateContext<EstablishmentStateModel>, { payload }: SetCurrentEstablishment) {
    const state = context.getState();
    context.setState({
      ...state,
      establishment: payload,
    });
  }
}
