import { Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfService } from 'src/app/core/conf/app-conf.service';
import { Select } from '@ngxs/store';
import { AppState } from 'src/app/core/store/app/app.state';
import { TenantModel } from 'src/app/core/models/tenant.model';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export const TENANTS_URL = 'tenants';
export const USERS_URL = 'users';
export const MEDICAL_CHECKUP_URL = 'medicalCheckups';
export const MEDICAL_CENTER_URL = 'medicalCenters';
export const COLLABORATORS_URL = 'collaborators';
export const ESTABLISHMENT_URL = 'entities';
export const COMPANIES_URL = 'companies';
export const ESTABLISHMENT_COMPLEMENTARY_ACTS_URL = 'entitiesComplementaryActs';

@Component({
  template: '',
})
export abstract class BaseApi<T> {
  abstract END_POINT_URL: string;
  httpClient: HttpClient;
  appConfService: AppConfService;
  routeSub: Subscription | undefined;
  tenantId: string | null;

  @Select(AppState.getCurrentAppClient)
  currentClientObserver: Observable<TenantModel>;

  protected constructor(http: HttpClient, config: AppConfService, private route: ActivatedRoute) {
    this.httpClient = http;
    this.appConfService = config;
  }

  // Construct whole URL

  get api_base_url(): string {
    return this.appConfService.env?.businessApiBaseUrl ?? '';
  }

  get api(): string {
    this.tenantId = this.route.snapshot.params.tenantId ?? this.route.snapshot.firstChild?.params.tenantId;
    return `${this.api_base_url}/tenants/${this.tenantId}/${this.END_POINT_URL}`;
  }

  // Basic API Calls

  getAll(): Observable<Array<T>> {
    return this.httpClient.get<Array<T>>(`${this.api}`);
  }

  getById(id: number): Observable<T> {
    return this.httpClient.get<T>(`${this.api}/${id}`);
  }

  create(payload: T | any): Observable<T> {
    return this.httpClient.post<T>(`${this.api}`, payload);
  }

  update(id: number, payload: T | any): Observable<any> {
    return this.httpClient.put<T | any>(`${this.api}/${id}`, payload);
  }

  delete(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${this.api}/${id}`);
  }
}
