import { TenantModel } from 'src/app/core/models/tenant.model';
import { UserMetadataModel } from 'src/app/core/models/user-metadata.model';

export class SetAppClient {
  static readonly type = '[App] Set Used Client';
  constructor(public readonly payload: TenantModel) {}
}

export class SetUserMetadata {
  static readonly type = '[App] Set User Metadata';
  constructor(public readonly payload: UserMetadataModel) {}
}
