import {Action, Selector, State, StateContext} from '@ngxs/store';
import {MedicalCenterModel} from "src/app/core/models/medical-center.model";
import {SetMedicalCenterMetadata} from './medicalcenter.actions';
import { Injectable } from '@angular/core';

export interface MedicalCenterStateModel {
  medicalCenterMetadata: MedicalCenterModel | null;
}

@State<MedicalCenterStateModel>({
  name: 'MedicalCenterState',
  defaults: {
    medicalCenterMetadata: null,
  },
})

@Injectable()
export class MedicalCenterState {
  // Selectors
  @Selector()
  static getMedicalCenterMetadata(state: MedicalCenterStateModel): MedicalCenterModel | null {
    return state.medicalCenterMetadata;
  }

  // Actions
  @Action(SetMedicalCenterMetadata)
  SetMedicalCenterMetadata(context: StateContext<MedicalCenterStateModel>, {payload}: SetMedicalCenterMetadata) {
    const state = context.getState();
    context.setState({
      ...state,
      medicalCenterMetadata: payload,
    });
  }
}
