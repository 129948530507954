import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard.service';


const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./my-perimeter/my-perimeter.module').then((m) => m.MyPerimeterModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'tenant/:tenantId/management',
    loadChildren: () => import('./management/management.module').then((m) => m.ManagementModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'tenant/:tenantId/complementary-acts',
    loadChildren: () => import('src/app/complementary-acts/complementary-acts.module').then((m) => m.ComplementaryActsModule),
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
