import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetCollaborator, SetMedicalCheckupHistory } from 'src/app/core/store/collaborator/collaborator.actions';
import { CollaboratorModel } from 'src/app/core/models/collaborator.model';
import { ListResultModel } from 'src/app/core/models/list-result.model';
import { MedicalCheckupListItemModel } from 'src/app/core/models/medical-checkup-list-item.model';
import { Injectable } from '@angular/core';

export interface CollaboratorStateModel {
  collaborator: CollaboratorModel | null;
  medicalCheckupHistory: ListResultModel<MedicalCheckupListItemModel> | null;
}

@State<CollaboratorStateModel>({
  name: 'CollaboratorState',
  defaults: {
    collaborator: null,
    medicalCheckupHistory: null,
  },
})

@Injectable()
export class CollaboratorState {
  // Selectors
  @Selector() static getCurrentCollaborator(state: CollaboratorStateModel): CollaboratorModel | null {
    return state.collaborator;
  }

  // Actions
  @Action(SetCollaborator)
  setAppClient(context: StateContext<CollaboratorStateModel>, { payload }: SetCollaborator) {
    const state = context.getState();
    context.setState({
      ...state,
      collaborator: payload,
    });
  }

  // Selectors
  @Selector() static getMedicalCheckupHistory(
    state: CollaboratorStateModel,
  ): ListResultModel<MedicalCheckupListItemModel> | null {
    return state.medicalCheckupHistory;
  }

  // Actions
  @Action(SetMedicalCheckupHistory)
  setMedicalCheckupHistory(context: StateContext<CollaboratorStateModel>, { payload }: SetMedicalCheckupHistory) {
    const state = context.getState();
    context.setState({
      ...state,
      medicalCheckupHistory: payload,
    });
  }
}
