import { Injectable } from '@angular/core';
import { BaseApi, USERS_URL } from 'src/app/core/api/base.api';
import { HttpClient } from '@angular/common/http';
import { AppConfService } from 'src/app/core/conf/app-conf.service';
import { ActivatedRoute } from '@angular/router';
import { UsersModel } from 'src/app/core/models/users.model';
import { Observable } from 'rxjs';
import { UserMetadataModel } from 'src/app/core/models/user-metadata.model';

@Injectable({
  providedIn: 'root',
})
export class UsersApi extends BaseApi<UsersModel> {
  END_POINT_URL = USERS_URL;

  constructor(http: HttpClient, config: AppConfService, route: ActivatedRoute) {
    super(http, config, route);
  }

  get api(): string {
    return `${this.api_base_url}/${this.END_POINT_URL}`;
  }

  getPersonalMetadata(): Observable<UserMetadataModel> {
    return this.httpClient.get<UserMetadataModel>(`${this.api}/me`);
  }
}
