import { Injectable } from '@angular/core';
import { UserMetadataModel } from 'src/app/core/models/user-metadata.model';
import { ActionsEnum } from 'src/app/shared/enums/actions.enum';

@Injectable()
export class PermissionsService {
  hasAccessToResource(
    metaData: UserMetadataModel | undefined | null,
    requiredActions: ActionsEnum[] | undefined | null,
  ): boolean {
    if (!metaData?.actions) return false;

    if (!requiredActions || requiredActions.length === 0) return true;

    return metaData.actions.some((action) => requiredActions.includes(action));
  }
}
