import { Component } from '@angular/core';
import { CustomIconsService } from './core/services/custom-icones.service';
import { DateAdapter } from '@angular/material/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { SetUserMetadata } from './core/store/app/app.actions';
import { UsersApi } from './core/api/users.api';
import { tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { UserMetadataModel } from 'src/app/core/models/user-metadata.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.container.html',
  styleUrls: ['./app.container.scss'],
})
export class AppContainer {
  title = 'client-app';
  isLoggedIn = false;
  userProfile: KeycloakProfile | null = null;

  constructor(
    private dateAdapter: DateAdapter<any>,
    private iconService: CustomIconsService,
    private readonly keycloak: KeycloakService,
    private usersApi: UsersApi,
    private store: Store,
  ) {
    dateAdapter.setLocale('fr');
  }

  async ngOnInit() {
    this.isLoggedIn = await this.keycloak.isLoggedIn();

    if (this.isLoggedIn) {
      this.userProfile = await this.keycloak.loadUserProfile();
      this.usersApi
        .getPersonalMetadata()
        .pipe(
          tap((val) => {
            const payload: UserMetadataModel = { account: this.userProfile, actions: val.actions };
            this.store.dispatch(new SetUserMetadata(payload));
          }),
        )
        .subscribe();
    }

    this.iconService.registerIcons();
  }
}
