import { CollaboratorModel } from 'src/app/core/models/collaborator.model';
import { ListResultModel } from 'src/app/core/models/list-result.model';
import { MedicalCheckupListItemModel } from 'src/app/core/models/medical-checkup-list-item.model';

export class SetCollaborator {
  static readonly type = '[Collaborator] Set Collaborator';
  constructor(public readonly payload: CollaboratorModel) {}
}

export class SetMedicalCheckupHistory {
  static readonly type = '[Collaborator] Set MedicalCheckup History';
  constructor(public readonly payload: ListResultModel<MedicalCheckupListItemModel>) {}
}
