import { Component, Input, OnInit } from '@angular/core';
import { MedicalCheckupApi } from 'src/app/core/api/medical-checkup.api';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { AppConfService } from 'src/app/core/conf/app-conf.service';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@UntilDestroy()
@Component({
  selector: 'app-navbar-layout',
  templateUrl: './navbar-layout.component.html',
  styleUrls: ['./navbar-layout.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'appNavBar' }],
})
export class NavbarLayoutComponent implements OnInit {
  constructor(
    private medicalCheckupApi: MedicalCheckupApi,
    private readonly keycloakService: KeycloakService,
    private appConfService: AppConfService,
  ) {}
  @Input()
  userInfo: KeycloakProfile | undefined;

  // Construct whole URL
  get admin_App_Url(): string {
    return this.appConfService.env?.adminAppUrl ?? '';
  }

  async ngOnInit(): Promise<void> {
    this.userInfo = await this.keycloakService.loadUserProfile();
  }

  getUserFullName(): string {
    if (!this.userInfo) return '';

    return `${this.userInfo.firstName ?? ''} ${this.userInfo.lastName ?? ''}`;
  }

  async logout(): Promise<void> {
    await this.keycloakService.logout(window.location.origin);
  }

  profile() {
    const keyCloackAuthServerUrl = this.keycloakService.getKeycloakInstance().authServerUrl ?? '';
    window.open(`${keyCloackAuthServerUrl}/realms/ayming/account/#/personal-info`, '_blank');
  }

  changePassword() {
    const keyCloackAuthServerUrl = this.keycloakService.getKeycloakInstance().authServerUrl ?? '';
    window.open(`${keyCloackAuthServerUrl}/realms/ayming/account/password`, '_blank');
  }

  testRandomCall(): void {
    // this.medicalCheckupApi
    //   .getById(1)
    //   .pipe(
    //     untilDestroyed(this),
    //     tap((res) => {}),
    //   )
    //   .subscribe();
  }

  toAdminSpace() {
    window.open(this.admin_App_Url, '_self');
  }
}
