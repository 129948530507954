import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppConfService {
  env: ENV_VARIABLES | null = null;

  constructor(private http: HttpClient) {}

  load(): Promise<ENV_VARIABLES> {
    return this.http
      .get<ENV_VARIABLES>('assets/json/env/env.json')
      .toPromise()
      .then((data) => {
        this.env = data;
        return data;
      })
      .catch((error) => {
        console.error('Failed to load configuration:', error);
        throw error;
      });
  }
}

export interface ENV_VARIABLES {
  clientId: string;
  scopes: string;
  authority: string;
  issuer: string;
  requireAuthHttps: boolean;
  businessApiBaseUrl: string;
  TenantId: string;
  adminAppUrl: string;
  ssrsUrl: string;
}
